<template>
  <sm-editable-item
    v-model="form"
    saveButtonText="Создать заявку"
    :validatePermission="false"
    :controllerName="controllerName"
    :isLoading="isLoadingPage"
    :disabledSaveButton="isDisabledSaveButton"
    :isLoadingSaveButton="isLoadingSaveButton"
    :breadcrumbs="breadcrumbs"
    :pageHeader="pageHeader"
    :fields="fields"
    @save="onSaveForm()"
    @cancel="onCancel('SupportRequestsForCurrentUser')"
  >
    <template #innerRequest>
      <radio-buttons
        v-model="form.innerRequest"
        :buttons="innerRequests"
      ></radio-buttons>
    </template>
  </sm-editable-item>
</template>

<script>
// mixins
import editableListItemCreate from '@/mixins/editableListItemCreate.js';
// vuex
import { mapState, mapActions } from 'vuex';
// components
import SmEditableItem from '@/components/views/SmEditableItem.vue';
import RadioButtons from '@/components/client-sites/RadioButtons.vue';

export default {
  name: 'SupportRequestForCurrentUserCreate',

  components: {
    SmEditableItem,
    RadioButtons,
  },

  mixins: [editableListItemCreate],

  data() {
    return {
      isLoadingPage: false,
      controllerName: 'SupportRequestsForCurrentUser',
      pageHeader: 'Создание заявки',
      isLoadingSaveButton: false,
      breadcrumbs: [
        {
          text: 'Заявки',
          route: { name: 'RequestsMain' },
        },
        {
          text: 'Мои заявки',
          route: { name: 'SupportRequestsForCurrentUser' },
        },
        {
          text: 'Создание заявки',
        },
      ],
      initialForm: {
        name: null,
        text: null,
        type: 19,
        priority: 2,
        cusID: null,
        aluserid: null,
        waitst: null,
        parentId: null,
        statusId: null,
        accID: null,
        innerRequest: null,
      },
      form: null,
      innerRequests: [
        { id: 0, name: 'Заявка от клиента' },
        { id: 1, name: 'Внутренняя заявка' },
      ],
    };
  },


  computed: {
    ...mapState({
      priorities: (state) => state.common.priorities,
      types: (state) => state.common.types,
      customers: (state) => state.common.customers,
      gisTypes: (state) => state.common.gisTypes,
      initiationStatuses: (state) => state.common.initiationStatuses,
      customerAccounts: (state) => state.common.customerAccounts,
      performers: (state) => state.common.performers,
    }),


    fields() {
      return [
        {
          tab: 'Основные',
          groups: [
            {
              groupTitle: '',
              form: [
                {
                  type: 'radio',
                  key: 'innerRequest',
                  label: 'Заявка от клиента',
                  trueValue: 1,
                  falseValue: 0,
                },
              ]
            },
            {
              groupTitle: '',
              form: [
                this.form.innerRequest === 0 && {
                  type: 'select',
                  key: 'cusID',
                  label: 'Клиент',
                  list: this.customers.data
                },
                {
                  type: 'text',
                  key: 'name',
                  label: 'Название',
                  attributes: {
                    required: !this.form.name
                  }
                },
                this.form.innerRequest === 0 && {
                  type: 'select',
                  key: 'accID',
                  label: 'Аккаунт',
                  list: this.customerAccounts.data
                },
                {
                  type: 'textarea',
                  key: 'text',
                  label: 'Текст заявки',
                  attributes: {
                    required: !this.form.text
                  }
                },

              ].filter(Boolean)
            },
            {
              groupTitle: '',
              form: [
                {
                  type: 'select',
                  key: 'type',
                  label: 'Тип заявки',
                  list: this.types.data,
                  attributes: {
                    required: !this.form.type
                  }
                },
              ]
            },
            {
              groupTitle: '',
              form: [
                {
                  type: 'select',
                  key: 'statusId',
                  label: 'Статус',
                  list: this.initiationStatuses.data,
                },
              ]
            },
            {
              groupTitle: '',
              form: [
                {
                  type: 'select',
                  key: 'priority',
                  label: 'Приоритет заявки',
                  list: this.priorities.data,
                  attributes: {
                    required: !this.form.priority
                  }
                },
              ]
            },
            {
              groupTitle: '',
              form: [
                {
                  type: 'select',
                  key: 'gtype',
                  label: 'Тип заявки ГИС',
                  list: this.gisTypes.data,
                },
              ]
            },
            {
              groupTitle: '',
              form: [
                {
                  type: 'select',
                  key: 'aluserid',
                  label: 'Сотрудник для автоматического перевода после создания',
                  list: this.performers.data,
                },
              ]
            },
            {
              groupTitle: '',
              form: [
                {
                  type: 'checkbox',
                  key: 'waitst',
                  label: 'Ставить в ожидание ответа от сотрудника',
                  trueValue: 1,
                  falseValue: 0
                },
              ]
            },
          ]
        },
      ];
    },

    isDisabledSaveButton() {
      const { name, text, priority, type } = this.form;

      return !name || !text || !priority || !type;
    }
  },

  created() {
    this.isLoadingPage = true;
    const { cusID, parentId } = this.$route.query;


    this.form = {
      ...this.form,
      ...(parentId && { parentId }),
      ...(cusID && { cusID })
    };
    const customers = this.getCommonList({ name: 'Customers' });
    const initiationStatuses = this.getCommonList({
      name: 'InitiationStatuses',
    });
    const gisTypes = this.getCommonList({ name: 'GisTypes' });
    const types = this.getCommonList({ name: 'types' });
    const priorities = this.getCommonList({ name: 'priorities' });
    const performers = this.getCommonList({ name: 'performers' });

    Promise.all([
      customers,
      initiationStatuses,
      gisTypes,
      priorities,
      types,
      performers,
    ]).then(() => {
      this.isLoadingPage = false;
    });
  },
  watch: {
  'form.cusID': {
      handler(newVal) {
        if (newVal) {
          this.getCommonList({
            name: 'CustomerAccounts',
            params: { customerId: newVal },
          });
        }
      },
    },
  },
  methods: {
    ...mapActions({
      addRequest: 'krisha/addRequest',
      getCommonList: 'common/getCommonList',
    }),
    showNotification(header, type, text) {
      this.$notify({
        header,
        text,
        type,
        timer: 5000,
      });
    },
    onSaveForm() {
      this.isLoadingSaveButton = true;
      this.addRequest(this.form).then(() => {
        this.showNotification('Заявка успешно создана', 'success')
        this.$router.push({ name: "SupportRequestsForCurrentUser" });
      }).catch(() => {
        this.showNotification('Ошибка', 'error', 'При сохраненнии данных произошла ошибка' )
      })
      .finally(() => {
        this.isLoadingSaveButton = false;
      });
    },

  },
};
</script>

<style lang="scss"></style>
